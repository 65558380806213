import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import CollaborationPress from '../components/collaboration-press'
import ContentContainer from '../containers/content'

export const query = graphql`
  query CollaborationPressTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    collaboration: sanityCollaboration(id: { eq: $id }) {
      id
      title
      artist
      location
      startDate
      endDate
      slug {
        current
      }
      metaDescription
      openGraph {
        image {
          ...SanityImage
        }
      }
      _rawFormattedTitle
      _rawPressLinks(resolveReferences: { maxDepth: 10 })
    }
  }
`

const CollaborationPressTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const collaboration = data && data.collaboration
  const collaborationOgImage = collaboration.openGraph && collaboration.openGraph.image

  return (
    <ContentContainer section="collaborations" singleView>
      {errors && <SEO title="GraphQL Error" />}
      {collaboration && (
        <SEO
          title={`${collaboration.title} - Press` || site.title}
          description={collaboration.metaDescription || site.description}
          keywords={site.keywords}
          image={collaborationOgImage || siteOgImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {collaboration && <CollaborationPress {...collaboration} />}
    </ContentContainer>
  )
}

export default CollaborationPressTemplate
