import { format } from 'date-fns'
import React from 'react'
import PortableText from './portableText'
import Download from '../images/icons/download.svg'
import ExternalLink from '../images/icons/external-link.svg'

class CollaborationPress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      artist,
      location,
      startDate,
      endDate,
      title,
      _rawFormattedTitle,
      _rawPressLinks,
    } = this.props

    return (
      <div className="page-container">
        <div className="collaboration-press">
          <div className="collaboration-press__header">
            <h1 className="collaboration-press__title">
              {title && artist && (
                <>
                  <span>
                    <em>
                      {_rawFormattedTitle ? (
                        <PortableText blocks={_rawFormattedTitle} />
                      ) : (
                        <>{title},&nbsp;</>
                      )}
                    </em>
                    {artist}
                  </span>
                  <br />
                </>
              )}
              {location && (
                <>
                  <span>{location}</span>
                  <br />
                </>
              )}
              {startDate && endDate && (
                <>
                  <span>
                    <time dateTime={startDate}>{format(startDate, 'DD.MM')}</time>
                    .–
                    <time dateTime={endDate}>{format(endDate, 'DD.MM.YYYY')}</time>
                  </span>
                </>
              )}
            </h1>
            <div className="collaboration-press__tags">
              <div className="btn content-tag content-tag--info">Press</div>
            </div>
          </div>
          <ul className="collaboration-press__articles">
            {_rawPressLinks &&
              _rawPressLinks.map((item, i) => {
                if (item.article) {
                  const article = item.article
                  return (
                    <li key={item._key}>
                      <a
                        className="press-link"
                        href={
                          article._rawDownload
                            ? article._rawDownload.asset.url
                            : article.externalLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        key={article._key}
                      >
                        <div className="btn icon-btn press-article-icon press-article-icon--mobile">
                          {article._rawDownload ? <Download /> : <ExternalLink />}
                        </div>
                        <div className="press-link__title">
                          {article.publisher ? (
                            <>
                              <span className="press-link__article-name">
                                <span>
                                  <div className="btn icon-btn press-article-icon press-article-icon--desktop">
                                    {article._rawDownload ? <Download /> : <ExternalLink />}
                                  </div>
                                  <span>{article.title}</span>
                                  {/* <span className="comma">,</span> */}
                                </span>
                              </span>
                              <em className="press-link__publisher">{article.publisher}</em>
                            </>
                          ) : (
                            <em className="press-link__article-name">
                              <span>
                                <div className="btn icon-btn press-article-icon press-article-icon--desktop">
                                  {article._rawDownload ? <Download /> : <ExternalLink />}
                                </div>
                                <span>{article.title}</span>
                              </span>
                            </em>
                          )}
                        </div>
                      </a>
                    </li>
                  )
                }
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default CollaborationPress
